import { Environment } from './environment'
import { localEnvironment as dev } from './environment.dev'
import { localEnvironment as etoe } from './environment.etoe'
import { localEnvironment as prod } from './environment.prod'
import { localEnvironment as sit } from './environment.sit'
import { localEnvironment as uat } from './environment.uat'

const environment = (): Environment => {
  let env
  if (location.host === 'servicesint.azurewebsites.net') {
    env = sit
  } else if (
    location.host === 'aurorasit.azurewebsites.net' ||
    location.host === 'esxp-aurora-sit.microsoft.com'
  ) {
    env = etoe
  } else if (
    location.host === 'servicesuat.microsoft.com' ||
    (window['FxpAppSettings'] &&
      window['FxpAppSettings'].EnvironmentName === 'UAT')
  ) {
    env = uat
  } else if (
    location.host === 'esxp.microsoft.com' ||
    (window['FxpAppSettings'] &&
      window['FxpAppSettings'].EnvironmentName === 'Production')
  ) {
    env = prod
  } else {
    env = dev
  }

  return env
}

export { environment }
