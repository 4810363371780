import {
  IAppService,
  IAppServiceWithSharedComponent,
  IAuthExceptionServiceEndPoints,
  IPartnerBundle,
  IServiceEndPoints,
  ISharedComponentConfig,
  ISharedComponents,
} from '@fxp/fxpservices'
import { environment } from './app/common/services/config/environmentHelper'
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

class VergeWebApplication_routes
  implements IAppService, IAppServiceWithSharedComponent {
  getRoutes(userContext: any): any {
    const routeInfo: any = {
      applicationName: 'vergeWebApplication',
      adminRules: {
        ResourcePermissions: {
          AllResourcesMandatory: true,
          Value: [
            {
              Resource: 'FxPPlatform.ResourceManagement',
              Permissions: { Value: ['All'] },
            },
          ],
        },
      },
      sharedBundles: [],
      routes: [
        {
          name: 'network',
          url: '/network',

generatedBundle: 'c04182601234-VergeModule-Bundle',
          data: {
            headerName: 'Network',
            breadcrumbText: 'Network',
            pageTitle: 'Network',
            partnerTelemetryName: 'GRM',
            ocvAreaName: 'GCE_Requests',
          },
        },
        {
          name: 'settings',
          url: '/settings',

generatedBundle: 'c04182601234-VergeModule-Bundle',
          data: {
            headerName: 'settings',
            breadcrumbText: 'settings',
            pageTitle: 'settings',
            partnerTelemetryName: 'GRM',
            ocvAreaName: 'GCE_Requests',
          },
        },
        {
          name: 'vdashboard',
          url: '/vdashboard',

generatedBundle: 'c04182601234-HawkEyeModule-Bundle',
          data: {
            headerName: 'Vdashboard',
            breadcrumbText: 'Vdashboard',
            pageTitle: 'Vdashboard',
            partnerTelemetryName: 'GRM',
            ocvAreaName: 'GCE_Requests',
          },
        },
        {
          name: 'MyProfile',
          url: '/myprofile?isRedirectfrom',

generatedBundle: 'c04182601234-VergeModule-Bundle',
          data: {
            headerName: 'My Profile',
            breadcrumbText: 'My Profile',
            pageTitle: 'My Profile',
            partnerTelemetryName: 'GRM',
            appNameForTelemetry: 'Verge',
            pageNameForTelemetry: 'Workbench',
            featureName: 'Profileinformation',
            actionName: 'ViewProfileinformation',
          },
        },
        {
          name: 'MyProfileBasic',
          url: '/profile/{alias:any}',

generatedBundle: 'c04182601234-VergeModule-Bundle',
          data: {
            headerName: 'My Profile',
            breadcrumbText: 'My Profile',
            pageTitle: 'My Profile',
            partnerTelemetryName: 'GRM',
            appNameForTelemetry: 'Verge',
            pageNameForTelemetry: 'Workbench',
            featureName: 'Profileinformation',
            actionName: 'ViewProfileinformation',
          },
        },
        {
          name: 'workbench',
          url: '/workbench',

generatedBundle: 'c04182601234-VergeModule-Bundle',
          data: {
            headerName: 'Workbench',
            breadcrumbText: 'Workbench',
            pageTitle: 'Workbench',
            partnerTelemetryName: 'GRM',
            appNameForTelemetry: 'Verge',
            pageNameForTelemetry: 'Workbench',
            featureName: 'Entry',
            subFeatureName: 'Entry',
            actionName: 'Entry',
          },
          redirectTo: 'workbench.talentWb',
        },
        {
          name: 'workbench.r360',
          url: '/profilemap',
          sticky: true,
          views: {
            r360: {

            },
          },
generatedBundle: 'c04182601234-VergeModule-Bundle',
          data: {
            headerName: 'Profile information',
            breadcrumbText: 'Profile information',
            pageTitle: 'Profile information',
            partnerTelemetryName: 'GRM',
            appNameForTelemetry: 'Verge',
            pageNameForTelemetry: 'Workbench',
            featureName: 'Profileinformation',
            actionName: 'ViewProfileinformation',
          },
          /*          authorizationRules: {
                        AllowedPermissions: {
                            Roles: {
                                Value: ['PS.Alias'],
                            },
                        },
                    },*/
        },
        {
          name: 'workbench.talentWb',
          url: '/DeliveryResource?tab&RAvailShowPreferenceTabByDefault&RAvailHighlightedPreferenceName',
          sticky: true,
          views: {
            talentwb: {

            },
          },
generatedBundle: 'c04182601234-VergeModule-Bundle',
          data: {
            headerName: 'Talent Workbench',
            breadcrumbText: 'Talent Workbench',
            pageTitle: 'Talent Workbench',
            partnerTelemetryName: 'GRM',
            appNameForTelemetry: 'Verge',
            pageNameForTelemetry: 'Talent-Workbench',
            featureName: 'TalentWorkbench',
            actionName: 'ViewTalentWorkbench',
          }
        },
        {
          name: 'workbench.labor',
          url: '/DeliveryResource?tab=Labor&RAvailShowPreferenceTabByDefault&RAvailHighlightedPreferenceName',
          sticky: false,
          views: {
            talentwb: {

            },
          },
generatedBundle: 'c04182601234-VergeModule-Bundle',
          data: {
            headerName: 'My Workbench',
            breadcrumbText: 'My Workbench',
            pageTitle: 'My Workbench',
            partnerTelemetryName: 'GRM',
            appNameForTelemetry: 'Verge',
            pageNameForTelemetry: 'Workbench',
            featureName: 'TalentWorkbench',
            actionName: 'ViewTalentWorkbench',
          },
          // authorizationRules: {
          //   AllowedPermissions: {
          //     Roles: {
          //       Value: ['PS.DeliveryResource'],
          //     },
          //   },
          // },
        },
        {
          name: 'workbench.laborweekview',
          url: '/DeliveryResource?tab=Labor%20Weekview&RAvailShowPreferenceTabByDefault&RAvailHighlightedPreferenceName',
          sticky: false,
          views: {
            talentwb: {

            },
          },
generatedBundle: 'c04182601234-VergeModule-Bundle',
          data: {
            headerName: 'My Workbench',
            breadcrumbText: 'My Workbench',
            pageTitle: 'My Workbench',
            partnerTelemetryName: 'GRM',
            appNameForTelemetry: 'Verge',
            pageNameForTelemetry: 'Workbench',
            featureName: 'TalentWorkbench',
            actionName: 'ViewTalentWorkbench',
          },
          // authorizationRules: {
          //   AllowedPermissions: {
          //     Roles: {
          //       Value: ['PS.DeliveryResource'],
          //     },
          //   },
          // },
        },
        {
          name: 'workbench.rmWb',
          url: '/ResourceManager?tab&projectId&requestId&source',
          sticky: true,
          views: {
            rmwb: {

            },
          },
generatedBundle: 'c04182601234-VergeModule-Bundle',
          data: {
            headerName: 'Staffing Workbench',
            breadcrumbText: 'My Workbench',
            pageTitle: 'Staffing Workbench',
            partnerTelemetryName: 'GRM',
            appNameForTelemetry: 'Verge',
            pageNameForTelemetry: 'Workbench',
            featureName: 'RMWorkbench',
            actionName: 'ViewRMWorkbench',
          },
          authorizationRules: {
            AllowedPermissions: {
              Roles: {
                Value: ['PS.ResourceManagement'],
              },
            },
          },
        },
        {
          name: 'workbench.managerWb',
          url: '/functionalmanager?tab&requestId',
          sticky: false,
          views: {
            managerWb: {

            },
          },
generatedBundle: 'c04182601234-VergeModule-Bundle',
          data: {
            headerName: 'Manager Workbench',
            breadcrumbText: 'Manager Workbench',
            pageTitle: 'Manager Workbench',
            partnerTelemetryName: 'GRM',
            appNameForTelemetry: 'Verge',
            pageNameForTelemetry: 'Manager-Workbench',
            featureName: 'ManagerWorkbench',
            actionName: 'ViewManager',
          },
          authorizationRules: {
            AllowedPermissions: {
              Roles: {
                Value: ['PS.FunctionalManagement'],
              },
            },
          },
        },
        {
          name: 'workbench.cesManagerWb',
          url: '/cesmanager?tab&requestId',
          sticky: false,
          views: {
            cesManagerWb: {

            },
          },
generatedBundle: 'c04182601234-VergeModule-Bundle',
          data: {
            headerName: 'Manager Workspace',
            breadcrumbText: 'Manager Workspace',
            pageTitle: 'Manager Workspace',
            partnerTelemetryName: 'GRM',
            appNameForTelemetry: 'Verge',
            pageNameForTelemetry: 'Manager-Workspace',
            featureName: 'ManagerWorkspace',
            actionName: 'ViewManagerWorkspace',
          },
          authorizationRules: {
            AllowedPermissions: {
              Roles: {
                Value: ['PS.CSAManager', 'PS.FunctionalManagerSales'],
              },
            },
          },
        },
        {
          name: 'workbench.rmv2',
          url: '/rm',
          sticky: false,
          views: {
            rmv2: {

            },
          },
generatedBundle: 'c04182601234-VergeModule-Bundle',
          data: {
            headerName: 'Staffing Workbench',
            breadcrumbText: 'Staffing Workbench',
            pageTitle: 'Staffing Workbench',
            partnerTelemetryName: 'GRM',
            appNameForTelemetry: 'Verge',
            pageNameForTelemetry: 'Workbench',
            featureName: 'RMWorkbench',
            actionName: 'ViewRMWorkbench',
          },
          authorizationRules: {
            AllowedPermissions: {
              Roles: {
                Value: ['PS.ResourceManagement'],
              },
            },
          },
        },
        {
          name: 'workbench.rmv2Param',
          url: '/rm?tab&requestId&projectId&source&demandSourceId',
          sticky: false,
          views: {
            rmv2: {

            },
          },
generatedBundle: 'c04182601234-VergeModule-Bundle',
          data: {
            headerName: 'Staffing Workbench',
            breadcrumbText: 'Staffing Workbench',
            pageTitle: 'Staffing Workbench',
            partnerTelemetryName: 'GRM',
            appNameForTelemetry: 'Verge',
            pageNameForTelemetry: 'Workbench',
            featureName: 'RMWorkbench',
            actionName: 'ViewRMWorkbench',
          },
          authorizationRules: {
            AllowedPermissions: {
              Roles: {
                Value: ['PS.ResourceManagement'],
              },
            },
          },
        },
        {
          name: 'workbench.requestorWb',
          url:
            '/Requestor?tab&projectId&requestId&ContractId&PackageId&SapProjectId&C1PackageId&Source',

generatedBundle: 'c04182601234-VergeModule-Bundle',
          data: {
            headerName: 'Requestor Workbench',
            breadcrumbText: 'My Workbench',
            pageTitle: 'Requestor Workbench',
            partnerTelemetryName: 'GRM',
            appNameForTelemetry: 'Verge',
            pageNameForTelemetry: 'Workbench',
            featureName: 'RequestorWorkbench',
            actionName: 'ViewRequestorWorkbench',
          },
          AllowedPermissions: {
            ResourcePermissions: {
              Value: [
                {
                  Resource: 'PS.Project',
                  Permissions: { Value: ['Add', 'Edit', 'View'] },
                },
              ],
            },
          },
        },
      ],
    }
    if (
      ['dev', 'sit'].includes(
        environment().name.toLowerCase())
    )
      routeInfo.routes.push(
        {
          name: 'VergeTest',
          url: '/VergeTest',

generatedBundle: 'c04182601234-TestModule-Bundle',
          data: {
            headerName: 'Verge Component Playground',
            breadcrumbText: 'Verge Component Playground',
            pageTitle: 'Verge Component Playground',
            partnerTelemetryName: 'GRM',
            appNameForTelemetry: 'Verge',
            pageNameForTelemetry: 'VergeTest',
          },
        }
      )
    return routeInfo
  }

  getServiceEndPoints(): Array<IServiceEndPoints> {
    const endpoints: IServiceEndPoints[] = environment().ServiceEndPoints

    return endpoints
  }

  getAuthExceptionEndpoints(): IAuthExceptionServiceEndPoints[] {
    const authExceptionEndpoints: IAuthExceptionServiceEndPoints[] = environment().AuthExceptionEndpoints

    return authExceptionEndpoints;
  }


  public getBundles(): Array<IPartnerBundle> {
    const baseUrl = environment().cdnBaseUrl
    const bundles = [
      {
        name: 'verge-app-bundle',
        files: [
          baseUrl + '/vendor.bundle.js',
          baseUrl + '/styles.bundle.js',
          baseUrl + '/grm-common.bundle.js',
          baseUrl + '/taco-components.bundle.js',
          baseUrl + '/grm-actions.bundle.js',
          baseUrl + '/grm-queue-mgmt.bundle.js',
          baseUrl + '/grm-rm-xp.bundle.js',
          baseUrl + '/grm-request-xp.bundle.js',
          baseUrl + '/verge-xp.bundle.js',
          baseUrl + '/grm2.bundle.js',
        ],
        sequentialLoading: true,
      },
    ]

    return bundles
  }

  getSharedComponentsInfo(): ISharedComponents {
    const emailPreferenceSharedComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeSharedModule-Bundle',
      componentDescription: 'This component renders email preference component.',
      componentInputs: [], // optional: Provide the list of input parameters with description.
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'verge-email-preference',
    }

    const feedbackSharedComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-RequestXpModule-Bundle',
      componentDescription: 'This component renders feedback component.',
      componentInputs: [], // optional: Provide the list of input parameters with description.
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'feedback-component',
    }

    const leadTimeComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-HawkEyeModule-Bundle',
      componentDescription: 'This component renders mini card.',
      componentInputs: [], // optional: Provide the list of input parameters with description.
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'mini-card',
    }

    const cardComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-HawkEyeModule-Bundle',
      componentDescription: 'This component renders mini card.',
      componentInputs: [], // optional: Provide the list of input parameters with description.
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'app-card',
    }

    const vergeNotificationMessageComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeSharedModule-Bundle',
      componentDescription:
        'This component renders notification message component.',
      componentInputs: [
        ['notificationMessage', 'Provide notification message and category'],
      ],
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'verge-notification-message',
    }
    const clarityInsights: ISharedComponentConfig = {

generatedBundle: 'c04182601234-CommonWebModule-Bundle',
      componentDescription:
        'This component enables activity tracking for Clarity',
      componentInputs: [
        ['projectCode', 'mandatory project code from Clarity registration'],
        [
          'customTags',
          'optional Map<string,string> (tag-name, value) of custom tags to be associated with the insights',
        ],
      ],
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'clarity',
    }

    const sampleWorkbenchComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeSharedModule-Bundle',
      componentDescription:
        'Sample shared component to be utilized in Wrokbench',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'workbench-sample',
    }

    const sampleComingSoonComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeSharedModule-Bundle',
      componentDescription:
        'Sample shared component to be utilized in Workbench',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'coming-soon',
    }

    const sampleE360RedirectionPage: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeSharedModule-Bundle',
      componentDescription:
        'Sample shared component to be utilized in Workbench',
      componentInputs: [
        ['delay', 'delay for automatic redirection in seconds'],
        ['redirectUrl', 'redirect url'],
        ['redirectText', 'redirect text'],
        ['redirectButtonText', 'redirect button text'],
        ['openInNewTab', 'boolean input variable to open in new tab (default value is false)'],
        ['workbenchContext', 'workbench context input variable']
      ],
      componentOutputs: [],
      componentName: 'e360-redirection-page',
    }

    const engagementTabComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeSharedModule-Bundle',
      componentDescription:
        'Shared component to be utilized in Workbench for Engagement',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'workbench-engagement',
    }

    const projectTabComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeSharedModule-Bundle',
      componentDescription:
        'Shared component to be utilized in Workbench for Engagement',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'workbench-project',
    }

    const dealWorkbenchComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeSharedModule-Bundle',
      componentDescription:
        'Shared component to be utilized in Workbench for Deal',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'deal-workbench',
    }

    const contractWorkbenchComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeSharedModule-Bundle',
      componentDescription:
        'Shared component to be utilized in Workbench for Contract',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'contract-workbench',
    }

    const vergeControlIntegrationComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeSharedModule-Bundle',
      componentDescription:
        'Shared component to be utilized in Workbench for Verge Controls',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'verge-controls-integration',
    }

    const clmPackageWorkbenchComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeSharedModule-Bundle',
      componentDescription:
        'Shared component to be utilized in Workbench for CLM Package',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'clm-package-workbench',
    }

    const packageWorkbenchComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeSharedModule-Bundle',
      componentDescription:
        'Shared component to be utilized in Workbench for Package',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'package-workbench',
    }

    const passportWorkauthcomponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeModule-Bundle',
      componentDescription: 'passport and workauth component',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'passport-workauth'
    }
    const profileAndShifttabcomponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeModule-Bundle',
      componentDescription: 'profile and shift component',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'ProfileAndShiftTab'
    }

    const networkservicecomponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeModule-Bundle',
      componentDescription: 'check network speed',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'network-service'
    }

    const vergeControlTemplateComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeSharedModule-Bundle',
      componentDescription:
        'Shared component to be utilized in Workbench for Verge Control library',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'verge-control-template',
    }

    const vergeDashboardComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-HawkEyeModule-Bundle',
      componentDescription: 'Shared component for operational dashboard',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'v-dashboard',
    }

    const FeedbackAsPopupComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeFeedbackModule-Bundle',
      componentDescription:
        'This component renders verge popup feedback component.',
      componentInputs: [
        ['sourceComponentName', ''],
        ['sourceAppName', ''],
        ['surveyID', ''],
      ], // optional: Provide the list of input parameters with description.
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'verge-feedbackcpopup',
    }
    const NPSFeedbackPopupComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeFeedbackModule-Bundle',
      componentDescription:
        'This component renders verge  popup NPS feedback component.',
      componentInputs: [
        ['sourceComponentName', ''],
        ['sourceAppName', ''],
        ['surveyID', ''],
      ], // optional: Provide the list of input parameters with description.
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'verge-nps-feedbackpopup',
    }
    const networkConnectionsComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeModule-Bundle',
      componentDescription: 'Network Connections Component',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'network-connections',
    }

    const networkWbSharedComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeModule-Bundle',
      componentDescription: 'Network Connections Component',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'network-wbshared',
    }

    const vergeFeedbackSharedComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeFeedbackModule-Bundle',
      componentDescription: 'This component renders verge feedback component.',
      componentInputs: [
        ['sourceComponentName', ''],
        ['sourceAppName', ''],
        ['surveyID', ''],
        ['onPageLoadEvent', ''],
      ], // optional: Provide the list of input parameters with description.
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'verge-feedback',
    }

    const vergeNPSFeedbackSharedComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeFeedbackModule-Bundle',
      componentDescription: 'This component renders verge feedback component.',
      componentInputs: [
        ['sourceComponentName', ''],
        ['sourceAppName', ''],
        ['surveyID', ''],
        ['onPageLoadEvent', ''],
      ], // optional: Provide the list of input parameters with description.
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'verge-nps-feedback',
    }

    const rosourceInfoCardProgress: ISharedComponentConfig = {

generatedBundle: 'c04182601234-VergeSharedModule-Bundle',
      componentDescription: 'This component renders circular progress bar',
      componentInputs: [['alias', ''], ['showProfilePercentage', '']],
      componentOutputs: [],
      componentName: 'resource-info-card-progress',
    }

    const vergeUnAuthComponent: ISharedComponentConfig = {

generatedBundle: 'c04182601234-UnauthorizedModule-Bundle',
      componentDescription: 'This component renders unauthorized message.',
      componentInputs: [['config', 'Configuration object.']],
      componentOutputs: [],
      componentName: 'verge-un-auth',
    }

    return {
      appName: 'vergeWebApplication',
      components: [
        feedbackSharedComponent,
        leadTimeComponent,
        cardComponent,
        clarityInsights,
        sampleWorkbenchComponent,
        sampleComingSoonComponent,
        dealWorkbenchComponent,
        contractWorkbenchComponent,
        vergeControlIntegrationComponent,
        packageWorkbenchComponent,
        clmPackageWorkbenchComponent,
        vergeControlTemplateComponent,
        engagementTabComponent,
        projectTabComponent,
        networkConnectionsComponent,
        vergeFeedbackSharedComponent,
        vergeNPSFeedbackSharedComponent,
        vergeDashboardComponent,
        networkWbSharedComponent,
        vergeNotificationMessageComponent,
        FeedbackAsPopupComponent,
        NPSFeedbackPopupComponent,
        emailPreferenceSharedComponent,
        passportWorkauthcomponent,
        profileAndShifttabcomponent,
        rosourceInfoCardProgress,
        networkservicecomponent,
        vergeUnAuthComponent,
        sampleE360RedirectionPage
      ],
      disableSharing: false,
      sharedBundles: [],
    }
  }
  public getGeneratedBundles() {
    var baseUrl = ''
    var currentScriptUrl = document.currentScript['src']
    if (currentScriptUrl.indexOf('exclude.inline.bundle') > 0) {
      currentScriptUrl = new Error().stack.match(/https?:\/\/[^\s/]+(?:\/?)/g)
      baseUrl = currentScriptUrl
        .filter((item) => item.indexOf('localhost:5000') === -1)[0]
        .toLowerCase()
    } else {
      baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/'))
    }
    return [
            {
                name: 'c04182601234-VergeModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/VergeModule.vendor.bundle.js',
                    baseUrl + '/VergeModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c04182601234-HawkEyeModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/HawkEyeModule.vendor.bundle.js',
                    baseUrl + '/HawkEyeModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c04182601234-TestModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/TestModule.vendor.bundle.js',
                    baseUrl + '/TestModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c04182601234-VergeSharedModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/VergeSharedModule.vendor.bundle.js',
                    baseUrl + '/VergeSharedModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c04182601234-RequestXpModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/RequestXpModule.vendor.bundle.js',
                    baseUrl + '/RequestXpModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c04182601234-CommonWebModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CommonWebModule.vendor.bundle.js',
                    baseUrl + '/CommonWebModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c04182601234-VergeFeedbackModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/VergeFeedbackModule.vendor.bundle.js',
                    baseUrl + '/VergeFeedbackModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c04182601234-UnauthorizedModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/UnauthorizedModule.vendor.bundle.js',
                    baseUrl + '/UnauthorizedModule.bundle.js'
                ],
                sequentialLoading: true           
            },

    ]
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(VergeWebApplication_routes, 'vergeWebApplication');
