import { Environment } from './environment'

export const localEnvironment: Environment = {
  name: 'PROD',
  cdnBaseUrl: 'https://verge-ui-prod.azureedge.net/verge2Web',
  msxEngagementUrl:
    'https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_engagement&id=',
  msxMilestoneUrl:
    'https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_engagementmilestone&id=',
  ServiceEndPoints: [
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://grm-queue-prod.trafficmanager.net/',
    },
    {
      clientId: '6db0b12a-edd4-4521-8406-a615ce8659d6',
      serviceEndPoint: 'https://oneprofileprodcoreapi.trafficmanager.net/',
    },
    {
      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9',
      serviceEndPoint: 'https://esxp.microsoft.com/rmavl/',
    },
    {
      clientId: '6db0b12a-edd4-4521-8406-a615ce8659d6',
      serviceEndPoint: 'https://oneprofileprodapipack.trafficmanager.net/',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://esxp-prod.azurefd.net/grm/rm/',
    },
    {
      clientId: '2092fb63-2773-45c9-9067-dc001542f916',
      serviceEndPoint:
        'https://professionalservices.microsoft.com/pomgmt/api/v1/PurchaseOrders',
    },
    {
      clientId: 'be983076-c700-4d52-aa05-874355076224',
      serviceEndPoint: 'https://professionalservices.microsoft.com/lmsprod/',
    },
    {
      serviceEndPoint: 'https://professionalservices.microsoft.com/dealmgmt/',
      clientId: 'cac9dc9d-055e-44b0-9053-450d978d03e7',
    },
    {
      serviceEndPoint:
        'https://professionalservices.microsoft.com/lmt-coreapi/',
      clientId: '22c68362-0f39-4ef6-a5a6-2eb389b8592f',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://metrics-prod-wus.azurewebsites.net/',
    },
    {
      serviceEndPoint: 'https://esxp-prod.azurefd.net/grm/staffing',
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://ps-sms-prod-g9dhbkazaye5esh2.z01.azurefd.net',
    },
    {
      clientId: '55bfa272d6644a21ad77d0e73bc8ccf9',
      serviceEndPoint:
        'https://professionalservices.microsoft.com/offers/api/queries',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://esxp-prod.azurefd.net/grm/staffing',
    },
    {
      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9',
      serviceEndPoint:
        'https://integration-platform-gateway-api-prod.azurefd.net',
    },
    {
      clientId: 'f9daa99c-6570-4781-a17c-d0c2d617ed6e',
      serviceEndPoint: 'https://pursuitexpappserviceprod.trafficmanager.net',
    },
    {
      clientId: 'https://loki.delve.office.com/',
      serviceEndPoint: 'https://loki.delve.office.com//user.read.all',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://esxp-prod.azurefd.net',
    },
    {
      clientId: '00000003-0000-0000-c000-000000000000',
      serviceEndPoint: 'https://graph.microsoft.com/v1.0/',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://fxpconfig-api-preprod-eus.azurewebsites.net/',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://esxp.microsoft.com/vergepreferenceapi/',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://vergefeedbackserviceprod.azurewebsites.net',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://esxp.microsoft.com/vergefeedbackapi/',
    },
    {
      clientId: '6db0b12a-edd4-4521-8406-a615ce8659d6',
      serviceEndPoint: 'https://vergegamificationserviceprod.azurewebsites.net',
    },
    {
      clientId: '447a0db4-30a0-4c46-9569-1f936a224165',
      serviceEndPoint: 'https://salesmanagement.trafficmanager.net/clm/contractprep-service/'
    },
    {
      clientId: '6db56845-5ebb-4b74-88d0-28cc0e41e163',
      serviceEndPoint: 'https://professionalservices.microsoft.com/dm/'
    }
  ],
  AuthExceptionEndpoints: [
    { serviceEndPoint: 'assets/config.json' }
  ],
}
