import { Environment } from './environment'

export const localEnvironment: Environment = {
  name:'UAT',
  cdnBaseUrl: 'https://verge-ui-uat.azureedge.net/verge2Web',
  msxEngagementUrl:
    'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_engagement&id=',
  msxMilestoneUrl:
    'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_engagementmilestone&id=',
  ServiceEndPoints: [
    {
      clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint: 'https://grm-queue-uat.trafficmanager.net/',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://availabilityuat-wus.azurewebsites.net/',
    },
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatcoreapi.trafficmanager.net/api/v1',
    },
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatapipack.trafficmanager.net/',
    },
    {
      clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint: 'https://esxp-uat.azurefd.net/grm/rm/',
    },
    {
      clientId: 'a3bb3f9c-915f-4b03-98a0-50ff35c47936',
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/o2c/pouat/api/v1/PurchaseOrders',
    },
    {
      clientId: 'fbb51f92-bfef-472c-97b4-e68f8eb1a145',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/lmsuat/',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/time-core/',
    },
    {
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/o2c/dealmgmt/uat',
      clientId: '90c71ba2-75ed-4469-b0cf-1e139f88e2af',
    },
    {
      clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint: 'https://metrics-uat-wus.azurewebsites.net',
    },
    {
      serviceEndPoint: 'https://feedback-api-preprod-eus.azurewebsites.net',
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
    },
    {
      clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint: 'https://ps-sms-api-uat.azurewebsites.net',
    },
    {
      clientId: 'ef8a1b99-cb01-4f9f-80aa-22ee1d628906',
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/offers/api/queries',
    },
    {
      clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint: 'https://esxp-uat.azurefd.net/grm/staffing',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint:
        'https://integration-platform-gateway-api-ppe-eus.azurewebsites.net',
    },
    {
      clientId: '36c9b89c-87e3-428a-aa6c-90c47513e9b5',
      serviceEndPoint: 'https://pursuitexpappserviceuat.trafficmanager.net',
    },
    {
      clientId: 'https://loki.delve.office.com/',
      serviceEndPoint: 'https://loki.delve.office.com//user.read.all',
    },
    {
      clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint: 'https://esxp-uat.azurefd.net',
    },
    {
      clientId: 'https://msxuat.crm.dynamics.com',
      serviceEndPoint: 'https://msxuat.crm.dynamics.com/api/data',
    },
    {
      clientId: 'https://msitsalesds-us-prod.cloudapp.net/',
      serviceEndPoint: 'https://coresalesapisuat.azure-api.net',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://fxpconfig-api-preprod-eus.azurewebsites.net/',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://preferenceapippe.azurewebsites.net/',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://vergefeedbackserviceppe.azurewebsites.net/',
    },
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://vergegamificationservicepp.azurewebsites.net',
    },
    {
      clientId: 'dc9bc535-225a-4eac-80b2-f2d787a3e20c',
      serviceEndPoint: 'https://servicesuat.microsoft.com/cpq/quotecore/api/',
    },
    {
      clientId: 'c1981655-bcfb-4d80-b3e4-3e09facdc7a2',
      serviceEndPoint: 'https://servicesuat.microsoft.com/clm/contractprep-service/',
    },
    {
      clientId: '181ef486-9e47-4ad6-9454-7a326432dc00 ',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/dmuat/'
    }
  ],
  AuthExceptionEndpoints: [
    { serviceEndPoint: 'assets/config.json' }
  ],
}
